import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";

import * as colors from "@material-ui/core/colors";
import { nameBasedColorClass } from "util/colorHelpers";

const colorStyles = Object.keys(colors).reduce((hash, key) => {
  hash[key] = {
    // eslint-disable-next-line import/namespace
    backgroundColor: colors[key][500]
  };

  return hash;
}, {});

export class ResourceAvatarComponent extends PureComponent {
  static propTypes = {
    avatarProps: PropTypes.object,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    hashName: PropTypes.string, // want to use an avatar and name-specific color? set the hashName
    icon: PropTypes.node,
    logo: PropTypes.shape({
      url: PropTypes.string
    }),
    name: PropTypes.string
  };

  getColorClass() {
    const { color, classes, name, hashName } = this.props;

    if (color && colorStyles[color]) {
      return classes[color];
    }

    const colorKey = nameBasedColorClass(colors, name || hashName);

    return classes[colorKey];
  }

  getCharacter() {
    const { name } = this.props;

    if (!name) {
      return "";
    }

    return (name[0] || "").toUpperCase();
  }

  render() {
    const { logo, name, icon, avatarProps } = this.props;

    if (logo && logo.url) {
      return <Avatar src={logo.url} role="presentation" {...avatarProps} />;
    }

    if (name) {
      return (
        <Avatar className={this.getColorClass()} {...avatarProps}>
          {this.getCharacter()}
        </Avatar>
      );
    }

    // default case
    return (
      <Avatar className={this.getColorClass()} {...avatarProps}>
        {icon}
      </Avatar>
    );
  }
}

export default withStyles(colorStyles)(ResourceAvatarComponent);
