import DataModel from './Model';
import { Client as ClientSchema } from 'types/schema';

const resourcePath = '/clients';
export class Client extends DataModel<ClientSchema, typeof resourcePath> {
  static resourcePath = resourcePath;
  static resourceParam = 'client';
  static cacheKey = 'Client';

  static MemberRoutes = {
    Archive: 'archive'
  };
}
