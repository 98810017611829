import React, { Component } from "react";
import PropTypes from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
import ArchiveIcon from "@material-ui/icons/Archive";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Client } from "resources/Client";
import { withResource } from "containers/withResource";
import { idPropType } from "util/customPropTypes";

export class ArchiveClientDialogComponent extends Component {
  static propTypes = {
    id: idPropType, // required if open, required by withResource
    isLoading: PropTypes.bool, // provided by withResource
    onClose: PropTypes.func,
    onFailure: PropTypes.func,
    onMemberMethod: PropTypes.func, // provided by withResource
    onShowMessage: PropTypes.func, // provided by withResource
    onSuccess: PropTypes.func
  };

  isLoading() {
    const { isLoading } = this.props;

    return isLoading;
  }

  handleArchive = (e) => {
    // log in caller
    e.stopPropagation();

    const { onSuccess, onFailure, onMemberMethod, onShowMessage } = this.props;

    const replaceSnackbarKey = onShowMessage(
      "Archiving... this may take a moment",
      "info"
    );
    onMemberMethod(
      Client.Method.Patch,
      Client.MemberRoutes.Archive,
      {},
      { onSuccess, onFailure, replaceSnackbarKey }
    );
  };

  render() {
    const {
      /* eslint-disable @typescript-eslint/no-unused-vars, react/prop-types */
      // this is a tough one... I want to use the spread operator to allow this
      // component to take any props that Dialog would take and pass them on,
      // however, it means that I also need to ignore other props passed to this
      // components, i.e., those provided by the Containers below. :shrug:
      fetchState, // NOSONAR
      fetchError, // NOSONAR
      id, // NOSONAR
      isLoading, // NOSONAR
      nextRefreshAt, // NOSONAR
      onCancelAutoRefresh, // NOSONAR
      onCollectionMethod, // NOSONAR
      onCreateResource, // NOSONAR
      onDestroyResource, // NOSONAR
      onFailure, // NOSONAR
      onListResources, // NOSONAR
      onMemberMethod, // NOSONAR
      onReadResource, // NOSONAR
      onRefresh, // NOSONAR
      onShowMessage, // NOSONAR
      onSuccess, // NOSONAR
      onUpdateResource, // NOSONAR
      /* eslint-enable @typescript-eslint/no-unused-vars, react/prop-types */
      ...dialogProps
    } = this.props;
    const { onClose } = dialogProps;

    return (
      <Dialog {...dialogProps}>
        <DialogTitle id="alert-dialog-title">Archive Account?</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-confirmation">
            Are you sure you wish to archive this Account?
          </DialogContentText>

          <DialogContentText id="alert-dialog-warning">
            Archiving this Account will archive it for all members.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            aria-label="Cancel Archive Account"
            onClick={onClose}
            disabled={this.isLoading()}
          >
            <CancelIcon />
            Cancel
          </Button>

          <Button
            aria-label="Confirm Archive Account"
            onClick={this.handleArchive}
            color="secondary"
            disabled={this.isLoading()}
          >
            <ArchiveIcon />
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const ResponsiveArchiveClientDialog = withMobileDialog({
  breakpoint: "xs"
})(ArchiveClientDialogComponent);

export default withResource({
  ModelClass: Client,
  refreshOnMount: false
})(ResponsiveArchiveClientDialog);
