import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { clientPropType } from "util/customPropTypes";
import { handlePreventEnter } from "../util/helpers";

export default class EditClientForm extends Component {
  static propTypes = {
    actionsWrapperComponent: PropTypes.elementType,
    children: PropTypes.node,
    client: clientPropType,
    contentWrapperComponent: PropTypes.elementType,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
  };

  inputRefs = {};

  handleSubmit = (_e) => {
    // log in caller
    const { onSubmit } = this.props;

    const formData = Object.keys(this.inputRefs).reduce((form, refKey) => {
      form[refKey] = this.inputRefs[refKey].value;
      return form;
    }, {});

    onSubmit(formData);
  };

  renderInputs() {
    const { client: { name, description } = {} } = this.props;
    return (
      <Fragment>
        <TextField
          defaultValue={name || ""}
          fullWidth
          helperText="ex. Testing Ideas"
          id="name"
          inputRef={(ref) => {
            ref && (this.inputRefs[ref.name] = ref);
          }}
          label="Client Name"
          margin="dense"
          name="name"
          onKeyPress={handlePreventEnter}
          type="text"
          variant="filled"
        />

        <TextField
          defaultValue={description || ""}
          fullWidth
          id="description"
          inputRef={(ref) => {
            ref && (this.inputRefs[ref.name] = ref);
          }}
          label="Description"
          margin="dense"
          multiline
          name="description"
          onKeyPress={handlePreventEnter}
          maxRows={4}
          type="text"
          variant="filled"
        />
      </Fragment>
    );
  }

  renderActions() {
    const { actionsWrapperComponent: ActionsWrapperComponent, onCancel } =
      this.props;

    return (
      <ActionsWrapperComponent>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={this.handleSubmit} color="primary">
          Save
        </Button>
      </ActionsWrapperComponent>
    );
  }

  render() {
    const { children, contentWrapperComponent: ContentWrapperComponent } =
      this.props;

    return (
      <ContentWrapperComponent>
        <form noValidate autoComplete="off">
          {children}

          {this.renderInputs()}

          {this.renderActions()}
        </form>
      </ContentWrapperComponent>
    );
  }
}
