import React, { Component } from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ResourceAvatar from "./ResourceAvatar";

export default class ListItemResourceAvatar extends Component {
  render() {
    return (
      <ListItemAvatar>
        <ResourceAvatar {...this.props} />
      </ListItemAvatar>
    );
  }
}
