import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import EditClientForm from "./EditClientForm";
import { Client } from "resources/Client";
import { withResource } from "containers/withResource";
import Accessible, { Features } from "components/common/Accessible";

const styles = (_theme) => ({});

export class CreateClientButtonComponent extends Component {
  static propTypes = {
    isLoading: PropTypes.bool, // provided by withResource
    onComplete: PropTypes.func,
    onCreateResource: PropTypes.func
  };

  state = {
    formData: {},
    isOpen: false,
    submissionError: null
  };

  isSubmitting() {
    const { isLoading } = this.props;

    return isLoading;
  }

  handleClickCreateNew = (_e) => {
    // logged
    this.setState({ isOpen: true });

    ReactGA.event({
      category: "View",
      action: "Opened",
      label: "Create Client"
    });
  };

  handleCancelCreateNew = (_e) => {
    // logged
    this.setState({ isOpen: false });

    ReactGA.event({
      category: "View",
      action: "Closed",
      label: "Create Client"
    });
  };

  handleSubmit = (formData) => {
    // log in caller
    const { onCreateResource, onComplete } = this.props;

    onCreateResource(formData, {
      onSuccess: (resource) => {
        this.handleCancelCreateNew(resource);
        this.setState({ formData: {}, submissionError: null });
        onComplete && onComplete(resource);
      },
      onFailure: (submissionError) => {
        this.setState({ formData, submissionError });
      }
    });
  };

  render() {
    const { formData, isOpen, submissionError } = this.state;

    return (
      <Fragment>
        <Accessible to={Features.CreateClient}>
          <Button
            variant="outlined"
            size="large"
            color="default"
            onClick={this.handleClickCreateNew}
          >
            Create New
          </Button>
        </Accessible>

        <Dialog
          open={isOpen}
          onClose={this.handleCancelCreateNew}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Create Account</DialogTitle>

          <EditClientForm
            onCancel={this.handleCancelCreateNew}
            onSubmit={this.handleSubmit}
            formData={formData}
            error={submissionError}
            contentWrapperComponent={DialogContent}
            actionsWrapperComponent={DialogActions}
          >
            <DialogContentText>
              Accounts can be used to organize your Portfolios. For example, you
              may want a &ldquo;personal account&rdquo; for testing, and one
              Account for each of your customers.
            </DialogContentText>

            <DialogContentText>
              Create a new account for each customer or group of Portfolios you
              wish to maintain.
            </DialogContentText>
          </EditClientForm>
        </Dialog>
      </Fragment>
    );
  }
}

const StyledComponent = withStyles(styles)(CreateClientButtonComponent);
export default withResource({
  ModelClass: Client,
  refreshOnMount: false
})(StyledComponent);
