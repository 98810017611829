import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import ArchiveIcon from "@material-ui/icons/Archive";
import { idPropType } from "util/customPropTypes";
import { a11yOnClick } from "../util/helpers";
import ArchiveClientDialog from "./ArchiveClientDialog";
import { noop } from "util";

export class ArchiveClientMenuItemContent extends Component {
  static propTypes = {
    clientId: idPropType.isRequired, // required by Client resource class
    label: PropTypes.string, // provided by the default Component below
    onClick: PropTypes.func,
    onComplete: PropTypes.func
  };

  static defaultProps = {
    onComplete: noop
  };

  state = {
    showArchiveDialog: false
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) onClick();

    this.handleShowArchiveDialog();
  };

  handleHideArchiveDialog = () => {
    this.setState({
      showArchiveDialog: false
    });

    // logging n/a
  };

  handleShowArchiveDialog = () => {
    // logged
    this.setState({
      showArchiveDialog: true
    });

    ReactGA.event({
      category: "View",
      action: "Opened",
      label: "Confirm Archive Client"
    });
  };

  handleSuccess = () => {
    // logged
    this.setState({ showArchiveDialog: false });

    const { onComplete } = this.props;

    ReactGA.event({
      category: "Editing",
      action: "Archived",
      label: "Client"
    });

    if (!onComplete) return;

    onComplete();
  };

  renderArchiveDialog() {
    const { showArchiveDialog } = this.state;
    const { clientId } = this.props;

    if (!clientId) return;

    return (
      <ArchiveClientDialog
        fullWidth={true}
        id={clientId}
        onClose={this.handleHideArchiveDialog}
        onSuccess={this.handleSuccess}
        open={showArchiveDialog}
        maxWidth="sm"
      />
    );
  }

  render() {
    const { label } = this.props;
    // TODO: mute appearance when loading
    return (
      <Fragment>
        <ListItemIcon {...a11yOnClick(this.handleClick, this)}>
          <ArchiveIcon color="error" />
        </ListItemIcon>

        <ListItemText
          {...a11yOnClick(this.handleClick, this)}
          primary={label}
        />

        {this.renderArchiveDialog()}
      </Fragment>
    );
  }
}

// MenuItems cannot be wrapped in HOCs it seems, so we create a small class
// component (not a functional component) to avoid a "ref" issue
export default class ArchiveClientMenuItem extends Component {
  render() {
    const label = "Archive";

    return (
      <MenuItem aria-label={label}>
        <ArchiveClientMenuItemContent {...this.props} label={label} />
      </MenuItem>
    );
  }
}
