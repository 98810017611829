// h/t: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript#answer-8831937
export function simpleHash(str) {
  let hash = 0;

  if (!str || str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return hash;
}

const colorsToAvoid = ["common", "grey", "blueGrey"];
export function nameBasedColorClass(colors, name) {
  const nameHash = simpleHash(String(name));
  const colorKeys = Object.keys(colors).filter(
    (k) => colorsToAvoid.indexOf(k) < 0
  ); // grey is reserved for disabled states
  return colorKeys[Math.abs(nameHash) % colorKeys.length];
}

export function nameBasedColorValue(colors, name, weight = "500") {
  return colors[nameBasedColorClass(colors, name)][weight];
}
