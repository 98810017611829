import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import MenuIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { userClientPropType } from "util/customPropTypes";
import Accessible, { Features } from "components/common/Accessible";
import ArchiveClientMenuItem from "components/MenuItems/ArchiveClientMenuItem";
import EditClientMenuItem from "components/MenuItems/EditClientMenuItem";
import { noop } from "util";

export default class ClientListItemMenu extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onUpdate: PropTypes.func,
    userClient: userClientPropType.isRequired
  };

  static defaultProps = {
    onUpdate: noop
  };

  state = {};

  componentWillUnmount() {
    this.handleMenuClose();
  }

  handleMenuClick = (event) => {
    // logged
    this.setState({ menuElement: event.currentTarget });

    ReactGA.event({
      category: "View",
      action: "Opened",
      label: "Client Menu"
    });
  };

  handleMenuClose = () => {
    this.setState({ menuElement: null });

    // logging n/a
  };

  handleArchiveClient = () => {
    const { onComplete } = this.props;

    this.handleMenuClose();

    onComplete();
  };

  handleEditClient = () => {
    const { onComplete } = this.props;

    this.handleMenuClose();

    onComplete();
  };

  handleUpdateReference = (is_reference) => {
    const { onUpdate } = this.props;

    this.handleMenuClose();

    onUpdate({ is_reference });
  };

  renderArchiveMenuItem() {
    const { userClient: { client: { id } = {} } = {} } = this.props;

    return (
      <Accessible to={Features.ArchiveClient}>
        <ArchiveClientMenuItem
          clientId={id}
          onClick={this.handleMenuClose}
          onComplete={this.handleArchiveClient}
          showText={true}
        />
      </Accessible>
    );
  }

  renderEditMenuItem() {
    const { userClient } = this.props;

    return (
      <Accessible to={Features.EditClient}>
        <EditClientMenuItem
          onClick={this.handleMenuClose}
          onComplete={this.handleEditClient}
          userClient={userClient}
        />
      </Accessible>
    );
  }

  render() {
    return (
      <Fragment>
        <IconButton
          aria-controls="client-actions-menu"
          aria-haspopup="true"
          onClick={this.handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="client-actions-menu"
          anchorEl={this.state.menuElement}
          keepMounted
          open={Boolean(this.state.menuElement)}
          onClose={this.handleMenuClose}
        >
          {this.renderEditMenuItem()}

          <Divider />

          {this.renderArchiveMenuItem()}
        </Menu>
      </Fragment>
    );
  }
}
