import React from "react";
import PropTypes from "prop-types";
import useCurrentUserClient from "hooks/useCurrentUserClient";

// puts Feature.all.sort_by(&:key).map { |f| [f.key.camelize, f.key] }.to_h.to_json; nil
export const Features = {
  AnalysisCleansingConstraints: "analysis_cleansing_constraints",
  AnalysisEfficientFrontier: "analysis_efficient_frontier",
  AnalysisMarketConstraints: "analysis_market_constraints",
  AnalysisRebalanceInterval: "analysis_rebalance_interval",
  AnalysisSampleType: "analysis_sample_type",
  AnalysisSectorConstraints: "analysis_sector_constraints",
  ArchiveClient: "archive_client", //
  ArchivePortfolio: "archive_portfolio", //
  CloneAnalysis: "clone_analysis",
  ClonePortfolio: "clone_portfolio",
  CreateAnalysis: "create_analysis", //
  CreateCalibration: "create_calibration", //
  CreateClient: "create_client", //
  CreatePortfolio: "create_portfolio", //
  CreateReferencePortfolio: "create_reference_portfolio", //
  CreateTradableFromHistorical: "create_tradable_from_historical", //
  CurrentPositions: "current_positions", //
  CustomBenchmarks: "custom_benchmarks", //
  DeleteAnalysis: "delete_analysis", //
  EditAnalysis: "edit_analysis", //
  EditClient: "edit_client", //
  EditPortfolio: "edit_portfolio", //
  MovePortfolio: "move_portfolio", //
  PortfolioSecurities: "portfolio_securities",
  ResetAnalysis: "reset_analysis" //
  // RefreshPricing: "refresh_pricing"
};

const propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  override: PropTypes.bool,
  to: PropTypes.string
};

export function hasAccess(currentUserClient, feature) {
  if (!currentUserClient) return false;

  const { feature_set: featureSet = {} } = currentUserClient;

  return featureSet[feature];
}

export function hasAnyAccess(currentUserClient, ...features) {
  return features.some((f) => this.hasAccess(currentUserClient, f));
}

function AccessibleComponent({
  children,
  to,
  override,
  innerRef,
  ...restProps
}) {
  const { data: currentUserClient } = useCurrentUserClient()() || {};

  if (!override && !hasAccess(currentUserClient, to)) return null;

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { ...restProps, ref: innerRef })
  );
}
AccessibleComponent.propTypes = propTypes;

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <AccessibleComponent {...props} innerRef={ref} />
));
