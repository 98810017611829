import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { UserClient } from "resources/UserClient";
import { withResource } from "containers/withResource";
import { userClientPropType, idPropType } from "util/customPropTypes";
import EditClientForm from "../Clients/EditClientForm";

export class EditClientDialogComponent extends Component {
  static propTypes = {
    id: idPropType, // required if open, required by withResource
    isLoading: PropTypes.bool, // provided by withResource
    onClose: PropTypes.func,
    onCreateResource: PropTypes.func, // provided by withResource
    onFailure: PropTypes.func,
    onShowMessage: PropTypes.func, // provided by withResource
    onSuccess: PropTypes.func,
    onUpdateResource: PropTypes.func, // provided by withResource
    userClient: userClientPropType
  };

  isLoading() {
    const { isLoading } = this.props;

    return isLoading;
  }

  handleSubmit = (formData) => {
    // log in caller
    const { onSuccess, onFailure, onUpdateResource, onShowMessage } =
      this.props;

    const replaceSnackbarKey = onShowMessage(
      "Archiving... this may take a moment",
      "info"
    );
    onUpdateResource(formData, { onSuccess, onFailure, replaceSnackbarKey });
  };

  render() {
    const {
      userClient,
      /* eslint-disable @typescript-eslint/no-unused-vars, react/prop-types */
      // this is a tough one... I want to use the spread operator to allow this
      // component to take any props that Dialog would take and pass them on,
      // however, it means that I also need to ignore other props passed to this
      // components, i.e., those provided by the Containers below. :shrug:
      fetchState, // NOSONAR
      fetchError, // NOSONAR
      id, // NOSONAR
      isLoading, // NOSONAR
      nextRefreshAt, // NOSONAR
      onCancelAutoRefresh, // NOSONAR
      onCollectionMethod, // NOSONAR
      onCreateResource, // NOSONAR
      onDestroyResource, // NOSONAR
      onFailure, // NOSONAR
      onListResources, // NOSONAR
      onMemberMethod, // NOSONAR
      onReadResource, // NOSONAR
      onRefresh, // NOSONAR
      onShowMessage, // NOSONAR
      onSuccess, // NOSONAR
      onUpdateResource, // NOSONAR
      portfolioId, // NOSONAR
      /* eslint-enable @typescript-eslint/no-unused-vars, react/prop-types */
      ...dialogProps
    } = this.props;
    const { onClose } = dialogProps;

    return (
      <Dialog {...dialogProps} aria-labelledby="edit-client-dialog">
        <DialogTitle id="edit-client-dialog">Edit Account</DialogTitle>

        <EditClientForm
          actionsWrapperComponent={DialogActions}
          client={userClient}
          contentWrapperComponent={DialogContent}
          onCancel={onClose}
          onSubmit={this.handleSubmit}
        />
      </Dialog>
    );
  }
}
const ResponsiveEditClientDialog = withMobileDialog({
  breakpoint: "xs"
})(EditClientDialogComponent);

export default withResource({
  ModelClass: UserClient,
  refreshOnMount: false
})(ResponsiveEditClientDialog);
