import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ClientIcon from "@material-ui/icons/FolderOpen";
import SystemClientIcon from "@material-ui/icons/Settings";
import NonIdealIcon from "@material-ui/icons/Warning";
import { withResource, FetchActions } from "containers/withResource";
import NonIdealState from "components/NonIdealState/NonIdealState";
import ListItemResourceAvatar from "components/ResourceAvatar/ListItemResourceAvatar";
import { UserClient } from "resources/UserClient";
import { userClientsPropType } from "util/customPropTypes";
import ClientListItemMenu from "./ClientListItemMenu";

const alphaComparator = (a, b) => {
  const nameA = a.name || a.client.name;
  const nameB = b.name || b.client.name;
  if (nameA > nameB) return 1;
  if (nameA < nameB) return -1;

  return -0;
};

const systemThenAlphaComparator = (a, b) => {
  if (a.client.is_system && !b.client.is_system) return -1;
  if (!a.client.is_system && b.client.is_system) return 1;

  return alphaComparator(a, b);
};

const styles = (theme) => ({
  systemClient: {
    backgroundColor: theme.palette.background.light
  }
});

export class ClientListComponent extends Component {
  static propTypes = {
    classes: PropTypes.object, // provided by withStyles
    isLoading: PropTypes.bool, // provided by withResource
    onRefresh: PropTypes.func.isRequired,
    onSelectClient: PropTypes.func.isRequired,
    userClients: userClientsPropType
  };

  getSortedUserClients() {
    const { userClients = [] } = this.props;

    return userClients.sort(systemThenAlphaComparator);
  }

  isLoading() {
    const { isLoading } = this.props;

    return isLoading;
  }

  renderClientListItem = (userClient, index) => {
    const { classes, onSelectClient, onRefresh } = this.props;
    const { name: userClientName, client: { name, is_system: isSystem } = {} } =
      userClient;
    const icon = isSystem ? <SystemClientIcon /> : <ClientIcon />;
    const className = classnames(classes.client, {
      [classes.systemClient]: isSystem
    });

    return (
      <ListItem
        key={index}
        button
        className={className}
        onClick={() => onSelectClient(userClient)}
      >
        <ListItemResourceAvatar icon={icon} hashName={name} />

        <ListItemText primary={userClientName || name} secondary={name} />

        <ListItemSecondaryAction>
          <ClientListItemMenu
            onComplete={() => onRefresh()}
            onUpdate={this.handleUpdatePortfolio}
            userClient={userClient}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  renderClients() {
    const userClients = this.getSortedUserClients();

    if (userClients.length < 1) {
      return this.renderNoData();
    }

    return (
      <List>
        {/*className={classes.root}*/}
        {userClients.map(this.renderClientListItem)}
      </List>
    );
  }

  renderLoading() {
    return <CircularProgress />;
  }

  renderNoData() {
    const description = (
      <Fragment>
        <Typography>Expecting to see accounts here?</Typography>

        {/* if (clientCount > 1) */}

        <Typography>
          Try creating one, or, if you belong to more than one account, try
          switching accounts.
        </Typography>

        {/* ClientSwitcher */}
      </Fragment>
    );

    return (
      <NonIdealState
        IconComponent={NonIdealIcon}
        description={description}
        elevation={0}
        title="No clients found"
      />
    );
  }

  render() {
    if (this.isLoading()) {
      return this.renderLoading();
    }

    return this.renderClients();
  }
}

const StyledComponent = withStyles(styles)(ClientListComponent);
export default withResource({
  ModelClass: UserClient,
  dataKey: "userClients",
  refreshMethod: FetchActions.List
})(StyledComponent);
