import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Head from "next/head";

import {
  getDefaultRequestOptions,
  getServerRedirect,
  dispatchToCache
} from "src/helpers";
import { UserClient } from "resources/UserClient";
import { userClientsPropType } from "util/customPropTypes";
import ClientList from "components/Clients/Clients";

const propTypes = {
  userClients: userClientsPropType
};

export async function getServerSideProps(context) {
  const options = getDefaultRequestOptions(context);
  const model = new UserClient(null, options);

  const props = {};
  try {
    props.userClients = await model.list();
  } catch (e) {
    const redirect = getServerRedirect(context, e);

    if (redirect) return { redirect };
    // else, ignore, try again client-side
  }

  return { props };
}

export default function ClientsPage({ userClients }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatchToCache(dispatch, UserClient, userClients, true);
  });

  return (
    <>
      <Head>
        <title>
          Accounts | Portfolio Catapult | Data-Driven Investment Portfolios
        </title>
      </Head>
      <ClientList userClients={userClients} />
    </>
  );
}

ClientsPage.propTypes = propTypes;
