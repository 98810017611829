import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import { userClientPropType } from "util/customPropTypes";
import { a11yOnClick } from "../util/helpers";
import EditClientDialog from "./EditClientDialog";
import { noop } from "util";

export class EditClientMenuItemContent extends Component {
  static propTypes = {
    label: PropTypes.string, // provided by the default Component below
    onClick: PropTypes.func,
    onComplete: PropTypes.func,
    userClient: userClientPropType
  };

  static defaultProps = {
    onComplete: noop
  };

  state = {
    showEditDialog: false
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) onClick();

    this.handleShowEditDialog();
  };

  handleHideEditDialog = () => {
    this.setState({
      showEditDialog: false
    });

    // logging n/a
  };

  handleShowEditDialog = () => {
    // logged
    this.setState({
      showEditDialog: true
    });

    ReactGA.event({
      category: "View",
      action: "Opened",
      label: "Confirm Edit Client"
    });
  };

  handleSuccess = () => {
    // logged
    this.setState({ showEditDialog: false });

    const { onComplete } = this.props;

    ReactGA.event({
      category: "Editing",
      action: "Updated",
      label: "Client"
    });

    if (!onComplete) return;

    onComplete();
  };

  renderEditDialog() {
    const { showEditDialog } = this.state;
    const { userClient } = this.props;
    const { id } = userClient || {};

    if (!id) return;

    return (
      <EditClientDialog
        fullWidth={true}
        id={id}
        onClose={this.handleHideEditDialog}
        onSuccess={this.handleSuccess}
        open={showEditDialog}
        maxWidth="sm"
        userClient={userClient}
      />
    );
  }

  render() {
    const { label } = this.props;
    // TODO: mute appearance when loading
    return (
      <Fragment>
        <ListItemIcon {...a11yOnClick(this.handleClick, this)}>
          <EditIcon />
        </ListItemIcon>

        <ListItemText
          {...a11yOnClick(this.handleClick, this)}
          primary={label}
        />

        {this.renderEditDialog()}
      </Fragment>
    );
  }
}

// MenuItems cannot be wrapped in HOCs it seems, so we create a small class
// component (not a functional component) to avoid a "ref" issue
export default class EditClientMenuItem extends Component {
  render() {
    const label = "Edit";

    return (
      <MenuItem aria-label={label}>
        <EditClientMenuItemContent {...this.props} label={label} />
      </MenuItem>
    );
  }
}
