import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "next/router";
// import SpeedDial from "components/common/SpeedDial";
import withStyles from "@material-ui/core/styles/withStyles";

import PageContainer from "@jumbo-theme/components/PageComponents/layouts/PageContainer";

import BlockWrapper from "components/BlockWrapper/BlockWrapper";
import CreateClientButton from "./CreateClientButton";
import { withResource } from "containers/withResource";
import { Client } from "resources/Client";
import { CurrentUserClient } from "resources/UserClient";
import { truncateClientSpecificCacheAction } from "reducers/DB";
import { userClientsPropType } from "util/customPropTypes";
import ClientList from "./ClientList";
// import CreateAccountSpeedDialAction from "./CreateAccountSpeedDialAction";

export const INDEX_PATH = "/clients";

const styles = (_theme) => ({
  button: {}
});

export class ClientsIndexComponent extends Component {
  static propTypes = {
    isLoading: PropTypes.bool, // provided by withResource
    onShowMessage: PropTypes.func, // provided by withResource
    onTruncateCache: PropTypes.func, // provided by connect below
    onUpdateResource: PropTypes.func, // provided by withResource
    router: PropTypes.object.isRequired, // (from ReactRouter)
    userClients: userClientsPropType // optional
  };

  isLoading() {
    const { isLoading } = this.props;

    return isLoading;
  }

  linkTo({ id }) {
    return `${INDEX_PATH}/${id}`;
  }

  linkToPortfolios() {
    return "/portfolios";
  }

  handleResetCache = () => {
    // logging n/a
    const { onTruncateCache } = this.props;

    onTruncateCache();
  };

  handleSwitchClients = () => {
    // logging n/a
    this.handleResetCache();
    this.props.router.push({
      pathname: this.linkToPortfolios()
    });
  };

  handleSetUserClient = (clientId) => {
    // logged
    const { onShowMessage, onUpdateResource } = this.props;

    const replaceSnackbarKey = onShowMessage(
      "Switching Accounts... this may take a moment",
      "info"
    );
    onUpdateResource(
      { [Client.resourceParam]: { id: clientId } },
      {
        onSuccess: () => {
          this.handleSwitchClients();

          ReactGA.event({
            category: "View",
            action: "Switched Clients"
          });
        },
        replaceSnackbarKey,
        showSuccessMessage: false
      }
    );
  };

  handleSelectUserClient = (userClient) => {
    // logging n/a
    const { client_id: id } = userClient;

    this.handleSetUserClient(id);
  };

  handleSelectClient = (client) => {
    // logging n/a
    const { id } = client;

    this.handleSetUserClient(id);
  };

  renderCreateFeature() {
    return <CreateClientButton onComplete={this.handleSelectClient} />;
  }

  render() {
    const { userClients } = this.props;

    return (
      <PageContainer heading="Accounts">
        {/* TODO: Create Button */}

        <BlockWrapper xs={12} md={6} disableGutters={true}>
          <ClientList
            onSelectClient={this.handleSelectUserClient}
            userClients={userClients}
          />

          {this.renderCreateFeature()}
        </BlockWrapper>

        {/* consider adding speedDial to PageContainer to ensure bottom padding */}
        {/* <SpeedDial ariaLabel="Analysis Dashboard Context Menu">
        <CreateAccountSpeedDialAction />
      </SpeedDial> */}
      </PageContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onTruncateCache: truncateClientSpecificCacheAction(dispatch)
  };
}

export default compose(
  withRouter,
  withResource({
    ModelClass: CurrentUserClient,
    dataKey: "userClients",
    refreshOnMount: false
  }),
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ClientsIndexComponent);
